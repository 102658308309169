@media (max-width: 575.98px) {
    .header h2{
        font-size: 1.4rem;
    }
}

@media (max-width: 359.98px) {
    .header h2{
        font-size: 1.2rem;
    }
}

.links {
    background-color: var(--neon-black);
    padding: 5px;
    color: var(--neon-white);
}

.links a {
    text-shadow: none !important;
}