.linkedin {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 3rem;
    border: 4px var(--neon-socials) solid;
    border-radius: 16px;
    color: var(--neon-socials);
    box-shadow: var(--neon-socials-shadow), inset var(--neon-socials-shadow);
    text-shadow: var(--neon-socials-shadow);
}

.linkedin:hover {
    color: var(--neon-socials);
    text-shadow: var(--neon-blue-shadow);
    box-shadow: var(--neon-blue-shadow), inset var(--neon-blue-shadow);
    text-decoration: none;
}