.navbar {
    background-color: var(--background-color);
    position: fixed !important;
}

.nav {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    gap: 64px;
}

.nav a {
    background: none;
    color: var(--neon-white);
    border: none;
    font-size: 1.25rem;
    text-shadow: var(--neon-white-shadow-small);
    flex-shrink: 0;
    border: 2px solid rgba(0,0,0,0);
    width: 100%;
    text-align: center;
}

.nav a:hover > span, .nav a:focus-visible > span {
    border: 2px solid var(--neon-white) !important;
    border-radius: 5px;
    box-shadow: var(--neon-white-shadow-nav), inset var(--neon-white-shadow-nav);
    text-shadow: var(--neon-white-shadow-small) !important;
}

.nav a span {
    padding: 5px;
}

@media (max-width: 1199.98px) {
    .nav {
        gap: 0;
    }

    .navbar {
        width: 100%;
        top: 0;
    }
}